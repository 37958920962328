import React, { useMemo } from "react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { Get, baseURL } from "../utils/api";

function Gallery() {
  const cols = 4;
  const {
    data,
    isLoading = true,
    isError,
  } = Get({
    path: "files.php?path=images",
    key: "images",
  });

  const _images = useMemo(() => {
    if (isLoading) return [];
    else if (isError) return [];
    else if (!data.data?.success) return [];
    else return data.data.data;
  }, [data, isLoading, isError]);

  const images = useMemo(() => {
    let index = 0;
    const rows = [];

    while (index <= _images.length) {
      for (let col = 0; col < cols; col++) {
        const img = _images?.[index + col];

        if (!rows?.[col]) {
          rows[col] = [];
        }

        if (img) {
          rows[col].push(img);
        }
      }

      index += cols;
    }

    return rows;
  }, [_images]);

  return (
    <Container className="py-4">
      {isLoading ? (
        <p className="text-center">
          <Spinner />
        </p>
      ) : (
        <Row className="g-2">
          {images.map((col, i) => (
            <Col md={3} key={`col_${i}`}>
              {col.map((image) => (
                <Row key={image.name} className="mb-2 mx-0">
                  <Image
                    src={`${baseURL}${image.path}`}
                    className="p-0"
                    rounded
                  />
                </Row>
              ))}
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}

export default Gallery;
