import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Cover({ pageTitle }) {
  const { pathname } = useLocation();

  const title = useMemo(() => {
    const path_arr = pathname.split("/");
    let path =
      pageTitle ??
      path_arr
        .map((p) => p.charAt(0).toLocaleUpperCase() + p.slice(1))
        .join(" ");

    const _title = `${path} ${
      path.length > 1 ? "-" : ""
    } Islamic Culture Development Foundation`;

    document.title = _title;

    return path;
  }, [pathname, pageTitle]);

  return (
    <div className="text-bg-primary">
      <Container className="text-center" style={{ height: "40vh" }}>
        <Row className="align-items-center h-100">
          <Col>
            <h2
              className="display-4 fw-bold"
              style={{ letterSpacing: "0.3rem" }}
            >
              {title}
            </h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
