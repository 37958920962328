import React, { useMemo } from "react";
import { Carousel, Image } from "react-bootstrap";
import { Get, baseURL } from "../utils/api";

export default function MainCarousel() {
  const {
    data,
    isLoading = true,
    isError,
  } = Get({
    path: "files.php?path=images/carousel",
    key: "carousel-images",
  });

  const images = useMemo(() => {
    if (isLoading) return [];
    else if (isError) return [];
    else if (!data.data?.success) return [];

    return data.data.data;
  }, [data, isLoading, isError]);

  return (
    <Carousel>
      {images.map((item, i) => (
        <Carousel.Item key={`carousel_item_${i}`}>
          <Image
            src={`${baseURL}${item.path}`}
            alt={`carousel_image_${item.name}`}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
