import Index from ".";
import About from "./about";
import Contact from "./contact";
import Gallery from "./gallery";
import Projects from "./projects";
import Links from "../data/links.json";

const Pages = {
  Index,
  About,
  Contact,
  Gallery,
  Projects,
};

export function getPage(path = "") {
  path = path.charAt(0).toLocaleUpperCase() + path.toLocaleLowerCase().slice(1);

  const Element = Pages?.[path];

  if (Element)
    return (
      <Element
        link={
          Links.find(
            (link) => link.slug.toLocaleLowerCase() === path.toLocaleLowerCase()
          ) ?? {}
        }
      />
    );

  return <div>Not found</div>;
}

export default Pages;
