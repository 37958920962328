import React, { useMemo } from "react";
import { Table, Container, Row, Col } from "react-bootstrap";
import { Get, baseURL } from "../utils/api";

function Projects() {
  const {
    data,
    isLoading = true,
    isError,
  } = Get({
    path: "files.php?path=files",
    key: "project-files",
  });

  const files = useMemo(() => {
    if (isLoading) return [];
    else if (isError) return [];
    else if (!data.data?.success) return [];

    return data.data.data;
  }, [data, isLoading, isError]);

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col xl={7} lg={9} md={10}>
          <Table>
            <thead>
              <tr>
                <th>Description</th>
                <th className="text-end">Download</th>
              </tr>
            </thead>

            <tbody>
              {files.map((file) => (
                <tr key={file.name}>
                  <td>
                    {file.name.charAt(0).toUpperCase() + file.name.slice(1)}
                  </td>
                  <td className="text-end">
                    <a
                      href={`${baseURL}${file.path}`}
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Projects;
