import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { BiSend } from "react-icons/bi";

function Contact() {
  const [validated, setValidated] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return setValidated(true);
    }
  }

  return (
    <Container className="py-3">
      <Row className="justify-content-center">
        <Col xl={7} lg={9} md={11}>
          <Row className="mb-3">
            <Col>
              <h5>Address 1</h5>
              <p className="mb-0 lead">
                22 Lower Personage street, Kissy-Shell, Freetown
              </p>
            </Col>

            {/* <Col>
              <h5>Address 2</h5>
            </Col> */}
          </Row>

          <Row className="mb-4">
            <Col>
              <h5>Emails</h5>
              <p className="mb-0">
                <a href="mailto: icfrelief@gmail.com">icfrelief@gmail.com</a>

                <br />

                <a href="mailto:dukuraymohamed@yahoo.co.uk">
                  dukuraymohamed@yahoo.co.uk
                </a>
              </p>
            </Col>

            <Col>
              <h5>Phones</h5>
              <p className="m-0">
                <a href="tel:+23231309632">+232 31 309-632</a>
                <br />
                <a href="tel:+23278883723">+232 78 883-723</a>
              </p>
            </Col>
          </Row>

          <Form noValidate={true} onSubmit={handleSubmit} validated={validated}>
            <h5>Have a word? Let talk..</h5>
            <Row className="mb-3">
              <Col md>
                <FloatingLabel label="Full name">
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Full name"
                    id="name"
                    required={true}
                    autoFocus={true}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please provide a your name
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col md>
                <FloatingLabel label="Email address">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email address"
                    id="email"
                    required={true}
                  />

                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email address
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <FloatingLabel label="Message" className="mb-4">
              <Form.Control
                as="textarea"
                name="message"
                style={{ height: "100px" }}
                placeholder="Type in your message"
                id="message"
                required={true}
              />

              <Form.Control.Feedback type="invalid">
                Please provide a message to send
              </Form.Control.Feedback>
            </FloatingLabel>

            <Button type="submit" className="px-4">
              <span>Send Message</span>
              <BiSend className="ms-2" />
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
