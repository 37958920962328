import React, { useLayoutEffect, useMemo, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Navbar,
  Offcanvas,
  Stack,
} from "react-bootstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Cover from "../components/cover";

export default function MainLayout({ links = [] }) {
  const { pathname } = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const current = useMemo(
    () => links.find(({ url }) => url === pathname) ?? {},
    [pathname, links]
  );

  useLayoutEffect(() => {
    function handleScroll() {
      setIsScrolled(window.scrollY >= 50);
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Stack className="min-vh-100">
      <header>
        <Navbar
          collapseOnSelect={true}
          className="align-items-end"
          variant="dark"
          expand="md"
          fixed="top"
          bg={isScrolled ? "primary" : "fade"}
        >
          <Container>
            <Navbar.Brand href="/">
              <Image
                src="/logo.png"
                width={30}
                className="me-2 bg-light border"
                roundedCircle={true}
              />
              <strong className="text-light">Islamic Culture Foundation</strong>
            </Navbar.Brand>

            <Navbar.Toggle />

            <Navbar.Offcanvas placement="end" responsive="md">
              <Offcanvas.Header closeButton={true} className="text-bg-light">
                <Offcanvas.Title>Navigation Menu</Offcanvas.Title>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <Nav className="ms-auto">
                  {/* <Nav.Item>
                    <NavLink to="/" className="nav-link">
                      Home
                    </NavLink>
                  </Nav.Item> */}

                  {links.map((link, index) => (
                    <Nav.Item key={`${link}_${index}`}>
                      <NavLink to={link.url} className="nav-link">
                        {link.title}
                      </NavLink>
                    </Nav.Item>
                  ))}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

        {current.useCover && <Cover />}
      </header>

      <main>
        <Outlet />
      </main>

      <footer className="mt-auto text-bg-primary py-2">
        <Container className="text-center">
          <span>&copy; Copyright {new Date().getFullYear()}. </span>
          <span>
            All rights&reg; reserved{" "}
            <strong className="text-primary">
              <abbr title="Islamic Culture Development Foundation">ICF</abbr>
            </strong>
          </span>
        </Container>
      </footer>
    </Stack>
  );
}
