import axios from "axios";
import { useQuery } from "react-query";

export const baseURL = process.env.NODE_ENV === "production" ? "/api/" : "";

const api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

async function fetch({ path = "" }) {
  return await api.get(path);
}

export function Get(props = { path: "", key: "" }) {
  const func = () => fetch(props);

  return useQuery(props?.key ?? Date.now(), func, {
    enabled: true,
    initialData: {},
    refetchOnWindowFocus: false,
  });
}
