import {
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Get, baseURL } from "../utils/api";
import MainCarousel from "../components/carousel";

function Index() {
  const {
    data,
    isLoading = true,
    isError,
  } = Get({
    path: "files.php?path=images",
    key: "images",
  });

  const images = useMemo(() => {
    if (isLoading) return [];
    else if (isError) return [];
    else if (!data.data?.success) return [];

    const img = data.data.data;

    const slice = img.length - 6;
    return img.slice(slice < 0 ? img.length : slice);
  }, [data, isLoading, isError]);

  return (
    <>
      <MainCarousel />

      <Container className="py-5">
        <Row className="gx-5">
          <Col>
            <Image
              src="/logo.png"
              rounded={true}
              className="bg-white shadow"
              fluid={true}
            />
          </Col>
          <Col lg={9} md={7} className="px-5">
            <h5 className="display-6">
              <strong>Welcome</strong>
            </h5>

            <p style={{ textAlign: "justify" }}>
              Islamic Cultural Development Foundation (ICF) is an Islamic
              Non-governmental organization operating as a humanitarian relief
              agency supporting hundreds of orphans, widows and other vulnerable
              groups in Sierra Leone.
              <br />
              <br />
              ICF also engages in development projects services including the
              construction of schools, Masjids, water wells for vulnerable
              communities across the country.
            </p>
          </Col>
        </Row>
      </Container>

      <div className="text-bg-light">
        <Container className="py-5">
          <Row>
            <Col>
              <strong className="display-6">Our Mission</strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Nostrum facere dolores repellendus exercitationem quidem
                accusantium nihil perspiciatis qui cupiditate inventore,
                deleniti dignissimos delectus laudantium eos corporis non
                assumenda nisi ut!
              </p>
            </Col>
            <Col>
              <strong className="display-6">Our Vision</strong>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quod
                cupiditate magnam voluptatum sapiente sint deleniti accusantium
                voluptate, nobis blanditiis pariatur optio rerum, et consectetur
                eveniet laudantium vero debitis ullam veritatis.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="w-100 px-2" style={{ overflowX: "hidden" }}>
        <Stack className="align-items-center py-4 image-carousel">
          <h5 className="fw-bold">Gallery</h5>

          {isLoading ? (
            <Spinner />
          ) : (
            <Stack
              gap={1}
              direction="horizontal"
              className="hide-scrollbar"
              style={{ overflowX: "auto" }}
            >
              {images.map((image) => (
                <Image
                  key={image.name}
                  src={`${baseURL}${image.path}`}
                  thumbnail
                  style={{ width: "16rem", height: "9rem", objectFit: "cover" }}
                />
              ))}
            </Stack>
          )}

          <div className="mt-5">
            <NavLink to="/gallery" className="btn btn-primary">
              More...
            </NavLink>
          </div>
        </Stack>
      </div>

      <div className="text-bg-primary py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xl={5} lg={7} md={9} className="text-center">
              <h5>Join our newsletter</h5>
              <Form>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  size="lg"
                  className="text-center"
                />
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Index;
