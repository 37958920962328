import React from "react";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";

function About() {
  return (
    <Container className="py-3">
      <Row className="justify-content-center">
        <Col xl={7} lg={9} md={11}>
          <Stack gap={4}>
            <section id="history">
              <h5>How it all started...</h5>

              <p style={{ textAlign: "justify" }}>
                Islamic culture Foundation was established in Sierra Leone in
                2000 and has operated over 15 years after the brutal rebel war
                and realisation of sustainable peace across all regions in
                Sierra Leone. It was initiated to promote Islamic culture and
                tradition and to educate the youths on cultural identities and
                skills needed in the creation of cultural heritage that impacts
                on the lives of the younger generation.
                <br />
                ICF has implemented programs in humanitarian relief for hundreds
                of beneficiaries in its operational areas for orphan children,
                out of school children, youths, deprived or less privileged and
                needy families.
                <br />
                The organisation is non-partisan, non-political and inclusive of
                all, irrespective of tribe, region and other differences. It is
                legally registered with relevant Ministries and has implemented
                programs in collaboration with partners and ministries,
                including the Ministry of Social Welfare, Ministry of youths,
                and Ministry of Basic and higher education in Sierra Leone.
              </p>
            </section>

            <section id="status">
              <h5>Where we are now...</h5>
              <p style={{ textAlign: "justify" }}>
                The organisation presently has an orphan programme, a relief
                program for less privilege families, a school and a higher
                Institute for learning in the eastern part of Freetown. The
                organisation is working closely with an umbrella organisation of
                Sierra Leone Muslim Missionaries Union to propagate cultural
                education and transformation amongst the younger generation
                <br />
                The core values of this organisation is cultural identity and
                heritage, knowledge and skills transfer to younger generation,
                nationalism, self-respect, integrity and honesty in delivering
                program services and activities to meet the needy and less
                privileged communities in different regions in Sierra Leone.
              </p>
            </section>

            <section id="mission">
              <h5>Our Mission</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum
                necessitatibus expedita nemo, tenetur ullam autem laborum
                reprehenderit molestiae. Sequi molestias, pariatur iste
                voluptatibus perspiciatis similique excepturi ducimus et dolorum
                neque.
              </p>
            </section>

            <section id="vision">
              <h5>Our Vision</h5>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veniam
                magni architecto officia fugiat animi, eligendi voluptas nemo
                debitis est, nihil aperiam voluptatibus deleniti unde dolorem
                incidunt, ducimus consequatur dolor fuga.
              </p>
            </section>

            <section id="theme">
              <h5>Thematic Areas </h5>
              <p>
                The Islamic Cultural Development Foundation operates in four
                thematic areas or programmes
              </p>
              <ol>
                <li>
                  <strong className="text-muted">
                    Sponsorship programme for orphans, widows and other
                    vulnerable groups
                  </strong>

                  <p>
                    With funds provided by the Islamic Association in Bahrain,
                    ICF is currently supporting over 100 orphans with fund to
                    cover their education, health and other basic needs. Close
                    to 100 needy families are also receiving livelihood support
                    from ICF through funds provided by the Islamic Association.
                  </p>
                </li>

                <li>
                  <strong className="text-muted">
                    Education and Da’awah Programme
                  </strong>

                  <p>
                    Through its Education and Da’awah Programme, ICF is working
                    with Education committees in the communities to support
                    basic Islamic and Da’awah education through the provision of
                    leaning materials, organising seminars for Muslim youth and
                    women’s empowerment across Sierra Leone
                  </p>
                </li>

                <li>
                  <strong className="text-muted">Development Programme</strong>

                  <p>
                    Our development project seeks to provide safe water and safe
                    environment to learn and worship through the construction of
                    Masjids, schools, Water wells, health facilities and Islamic
                    Daa’wah centres.
                  </p>
                </li>

                <li>
                  <strong className="text-muted">
                    Humanitarian Relief programme
                  </strong>

                  <p>
                    Our Humanitarian service is geared towards cushioning the
                    daily burden faced by communities, which is mostly based on
                    accessing basic food. ICF support the needy communities with
                    Sacrificial animals during Eid-ul adha, Ramadan Food
                    distribution as well as distribution of relief items (food
                    medicines, clothes, etc.) when emergency/disaster happens.
                  </p>
                </li>
              </ol>
            </section>

            <section id="team">
              <h5>Our Team</h5>
              <Row className="g-3">
                {Array.from({ length: 8 }, (_, i) => ({
                  name: "Sample " + i,
                  position: "Sample",
                })).map((staff, i) => (
                  <Col key={`staff_${i}`} xl={4} lg={3} md={4} sm={2}>
                    <Card
                      className="border-0 shadow px-3 pt-3"
                      style={{ cursor: "pointer" }}
                    >
                      <Card.Img src="/logo.png" />
                      <Card.Body className="text-center">
                        <strong>{staff.name}</strong>
                        <p className="m-0">
                          <small>{staff.position}</small>
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </section>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
