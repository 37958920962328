import { Route, Routes } from "react-router-dom";
import Index from "./pages";
import MainLayout from "./layout/main_layout";
import Links from "./data/links.json";
import { getPage } from "./pages/pages";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout
            links={Links.map((link) => ({ ...link, url: `/${link.url}` }))}
          />
        }
      >
        <Route index element={<Index />} />

        {Links.map((link) => (
          <Route path={link.url} key={link.slug} element={getPage(link.slug)} />
        ))}
      </Route>

      <Route path="*" element={<div>Not found</div>} />
    </Routes>
  );
}

export default App;
